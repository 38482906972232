body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#main-content {
  height: calc(100vh - 48px);
  width: calc(100vw - 265px);
  overflow: auto;
}

.h-100-overflow {
  height: 100%;
  overflow: auto;
}

#main-content:has(.h-100-overflow) {
  padding-right: 0;
}

.KonvaStage > * {
  margin: 0 auto;
}

.MuiTabs-flexContainer {
  overflow: auto;
}

.RaSidebar-fixed .MuiButtonBase-root,
.RaMenu-closed .AccordionMenuItems {
  padding-left: 0;
}

.MenuSearch {
  margin: 0 20px !important;
  width: auto !important;
}

.RaMenu-closed .MenuSearch {
  display: none;
}

.main-header {
  margin: 0px 0px 0.35em !important;
  font-family: Hind !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  letter-spacing: -0.02em !important;
  line-height: 46px !important;
}

.secondary-header {
  margin: 0px !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: -0.02em !important;
  line-height: 20px !important;
}

.Column .MuiAutocomplete-root {
  width: 100% !important;
}

.Column .MuiFormControl-root:has(.MuiSelect-select) {
  margin: 0 !important;
}

.Column .MuiFormGroup-root:has(.MuiSwitch-root) {
  margin-bottom: 20px;
}

#main-content:not(:has(.Dashboard)) {
  padding: 36px 50px;
}

#main-content:not(:has(.Dashboard)):has(.always-visible) {
  padding-right: 0;
}

.RaCreate-card,
.RaEdit-card {
  padding: 36px 52px;
}

.RaCreate-card .MuiCardContent-root,
.RaEdit-card .MuiCardContent-root,
.RaCreate-card .MuiToolbar-root,
.RaEdit-card .MuiToolbar-root {
  padding: 0;
}

.RaToolbar-desktopToolbar {
  background-color: transparent !important;
}

.MuiTableRow-root {
  height: 53px;
}

.RaDatagrid-tableWrapper {
  padding: 8px 24px;
}

/* List TopBar */
.RaList-main > .MuiToolbar-root {
  margin-bottom: 4px;
  padding: 28px 24px !important;
}

.RaList-main > .MuiToolbar-root form {
  padding: 0 !important;
  margin-top: -8px;
}

.RaList-main > .MuiToolbar-root form .MuiFormControl-root *:not(fieldset) {
  z-index: 2;
}

.RaList-main > .MuiToolbar-root form .MuiFormLabel-root {
  z-index: 3 !important;
}

.RaList-main > .MuiToolbar-root .filter-field:has(.MuiSwitch-root) {
  align-self: center;
}

/* List TopBar ButtonBar*/
.RaList-main > .MuiToolbar-root .MuiToolbar-root {
  padding: 0 !important;
  align-items: center;
  min-height: 48px;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.RaList-main > .MuiToolbar-root .MuiToolbar-root .MuiButton-root {
  padding: 18.5px 37.5px;
}

.ListHeader {
  padding-bottom: 12px;
  margin-bottom: 36px;
  border-bottom: 1px solid #e0e0e0;
}

.ListTitle {
  font-family: Hind;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: left;
}

.ListSummary {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.RaDatagrid-headerCell {
  top: -36px !important;
}

.MuiTabs-root + .MuiDivider-root {
  margin-bottom: 36px;
}

.RaTabbedShowLayout-content:has(.RecordSummary) {
  padding: 36px 52px !important;
}

.RaLabeled {
  margin-bottom: 2em !important;
}

.RaLabeled .RaLabeled-label {
  font-weight: bolder;
  font-size: 1em !important;
  line-height: 1em !important;
  margin-bottom: 0 !important;
}

.RaLabeled .MuiTypography-body2 {
  font-size: 1em !important;
  line-height: 1.5em !important;
}

.RaLabeled .MuiTypography-body2:empty::before,
.RaLabeled .MuiTypography-body2 div:not(.DebugHeader):empty::before {
  content: "--";
}

.MuiAutocomplete-popper * {
  word-break: break-all !important;
}

.RaUserMenu-userButton {
  color: revert !important;
  background-color: revert !important;
}

.vertical-tabs {
  display: flex;
}

.vertical-tabs .MuiTabs-root {
  flex-shrink: 0;
}

.vertical-tabs .RaTabbedShowLayout-content {
  flex-grow: 1;
}

.vertical-tabs .MuiTabs-flexContainer {
  flex-direction: column;
  align-items: baseline;
}

.vertical-tabs .MuiTabs-flexContainer > * {
  width: 100%;
  border-right: 3px solid transparent;
  align-items: flex-start;
}

.vertical-tabs .MuiTabs-flexContainer > .Mui-selected {
  border-right-color: inherit;
}

.vertical-tabs .MuiTabs-indicator {
  display: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink-me {
  animation: blinker 2s linear infinite;
}
